#loader {
  z-index: 999;
  background-color: #1e1d29;
  width: 100%;
  height: 100%;
  transition: all .3s;
  position: fixed;
  top: 0;
  left: 0;
  overflow-x: hidden;
}

#loader div {
  color: #ccc;
  text-align: center;
  white-space: nowrap;
  width: 100%;
  font-family: Verdana, sans-serif;
  font-size: 20px;
  position: relative;
  top: 40%;
}

#loader div b {
  animation: 4s linear infinite blink;
  animation-delay: calc(.5s * var(--i));
  border-bottom: .05em solid;
  font-size: 2.5em;
  line-height: 2em;
  transition: color 1s;
  display: inline-block;
}

#loader div p {
  margin: 1em;
  font-family: Courier New, monospace;
  line-height: 1em;
}

@keyframes blink {
  0%, 100% {
    color: #1e1d29;
  }

  5%, 95% {
    color: #fff;
  }
}

.evp-header {
  height: 40%;
}

.evp-main {
  height: 60%;
}

@media (width >= 1200px) and (width <= 1599px) {
  .evp-header {
    height: 36%;
  }

  .evp-main {
    height: 64%;
  }
}

@media (width <= 767px) {
  .evp-header {
    height: 35%;
  }

  .evp-main {
    height: 65%;
  }
}

html, body {
  background: #1e1d29;
  height: 100%;
}

body {
  text-shadow: 0 0 10px #216b58;
  -webkit-user-select: none;
  user-select: none;
  background: radial-gradient(at 90% 100%, #1a231a33, #1a231a1a, #1e1d2980, #1e1d29);
  overflow: hidden;
}

.evp-main {
  width: 100%;
  position: absolute;
  bottom: 0;
  overflow: hidden;
}

.evp-main ul {
  text-align: center;
}

.evp-main ul li {
  margin: 1em;
  display: inline-block;
}
/*# sourceMappingURL=index.2a44d08b.css.map */
