@import "variables";

#loader {
  background-color: $loader-bg;
  height: 100%;
  left: 0;
  overflow-x: hidden;
  position: fixed;
  top: 0;
  transition: .3s;
  width: 100%;
  z-index: 999;

  & div {
    color: $loader-color;
    font-size: 20px;
    position: relative;
    text-align: center;
    top: 40%;
    white-space: nowrap;
    width: 100%;
  }

  & div {
    font-family: Verdana, sans-serif;
    b {
      animation: blink 4s linear infinite;
      animation-delay: calc(.5s * var(--i));
      border-bottom: .05em solid;
      display: inline-block;
      font-size: 2.5em;
      line-height: 2em;
      transition: color 1s;
    }
    p {
      font-family: Courier New, monospace;
      margin: 1em; line-height: 1em;
    }
  }
}

@keyframes blink {
  0%, 100% { color: $loader-blink-color }
  5%, 95% { color: $loader-after-color }
}
