@import "loader";
@import "layout";

html, body {
  background: $body-bg;
  height: 100%;
}

body {
  background: radial-gradient(ellipse at 90% 100%, rgba(26, 35, 26, .2), rgba(26, 35, 26, .1), rgba($dark, .5), rgba($dark, 1));
  overflow: hidden;
  text-shadow: 0 0 10px $primary;
  user-select: none;
}

.evp-main {
  overflow: hidden;
  position: absolute;
  width: 100%;
  bottom: 0;

  ul {
    text-align: center;
    li {
      display: inline-block;
      margin: 1em;
    }
  }
}
