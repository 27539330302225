.evp-header {
  height: 40%;
}

.evp-main {
  height: 60%;
}

@media (min-width: 1200px) and (max-width: 1599px) {
  .evp-header {
    height: 36%;
  }

  .evp-main {
    height: 64%;
  }
}

@media (max-width: 767px) {
  .evp-header {
    height: 35%;
  }

  .evp-main {
    height: 65%;
  }
}
